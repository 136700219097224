import { useTranslation } from "react-i18next";

import { Box, makeStyles } from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
// import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
// import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
// import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import ViewCompactOutlinedIcon from "@material-ui/icons/ViewCompactOutlined";
// import EmojiObjectsOutlined from '@material-ui/icons/EmojiObjectsOutlined';
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import FilterHdrOutlinedIcon from "@material-ui/icons/FilterHdrOutlined";

import { AppLayoutProps } from "../../commons/components/layout/LayoutProvider";
import {
  isExternal,
  isInternal,
  isLab,
  isLocal,
  isStaging,
} from "../beaver/helpers/ServerProperties";
import { isSimplified } from "components/beaver/helpers/ServerProperties";

export interface BreadCrumbRoute {
  regexp: string;
  text: string;
  parentText: string;
  parentRoute: string;
}

const useStyles = makeStyles((theme) => ({
  logo: {
      [theme.breakpoints.up("sm")]: {
        minWidth: "612.5px", 
        minHeight: "175px"
      },
      maxWidth: "98vw", 
      maxHeight: "25vh", 
  },
}));

const useMyLayout = (): AppLayoutProps => {
  // This will trigger if TBSLayout or not!
  // for now if the domain has beavernext, then make it TBS Layout, otherwise false
  // to force it, just make the second false true, etc.
  const useTBSLayout: boolean = isExternal();

  const { t } = useTranslation();
  const classes = useStyles();

  // these are extra breadcrumb routes in regexp for TBS Layout only!
  const breadCrumbRoutes: BreadCrumbRoute[] = [
    {
      regexp: "/md5/.*report[/]{0,1}$",
      text: t("MD5 Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
    {
      regexp: "/url/.*report[/]{0,1}$",
      text: t("URL Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
    {
      regexp: "/sid/.*report[/]{0,1}$",
      text: t("SID Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
    {
      regexp: "/domain/.*report[/]{0,1}$",
      text: t("Domain Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
    {
      regexp: "/ip/.*report[/]{0,1}$",
      text: t("IP Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
    {
      regexp: "/email_address/.*report[/]{0,1}$",
      text: t("Email Address Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
    {
      regexp: "/phishing_kit/.*report[/]{0,1}$",
      text: t("Phishing Kit Report"),
      parentText: t("drawer.search"),
      parentRoute: "/search",
    },
  ];

  /* 
  The following menu items will show up in the Left Navigation Drawer
  It has 3 types of items at the root:
    1. item: This is a menu item and has the following properties:
                - unique id (you should use the translation id)
                - text value
                - route to link to 
                - optional icon
    2. divider: This is a divider bar for your menu, it has no properties
    3. group: This is a group of item those item will show up as nested list item 
                while the drawer is open and as a popover while the drawer is closed.
                it has the following properties:
                  - unique id (you should use the translation id)
                  - text value
                  - optional icon
                  - list of nested items (These can only be items, no group and divider support)
  */
  const MENU_ITEMS = [
    {
      type: "item" as "item",
      element: {
        id: "drawer.search",
        text: t("drawer.search"),
        icon: <SearchOutlinedIcon />,
        route: "/search",
      },
    },
    {
      hidden: isSimplified(),
      type: "group" as "group",
      element: {
        id: "drawer.priority",
        title: t("Priority"),
        icon: <StarBorderOutlinedIcon />,
        items: [
          {
            id: "drawer.priority_list",
            text: t("Priority List"),
            route: "/priority",
            nested: true,
          },
          {
            id: "drawer.priority_bulk_add",
            text: t("Add in Bulk"),
            route: "/priority/bulk",
            nested: true,
          },
        ],
      },
    },
    {
      hidden: isSimplified(),
      type: "group" as "group",
      element: {
        id: "drawer.tag_list",
        title: t("Tags"),
        icon: <LocalOfferOutlinedIcon />,
        items: [
          {
            id: "drawer.tag_list_tags",
            text: t("Malware Tags"),
            route: "/taglist",
            nested: true,
          },
          {
            id: "drawer.tag_list_phishing_url_md5",
            text: t("Phishing/Smishing Tags"),
            route: "/urlmd5/list#PHISHING",
            nested: true,
          },
          {
            id: "drawer.tag_list_cpanel_url_md5",
            text: t("CPanel Tags"),
            route: "/urlmd5/list#CPANEL",
            nested: true,
          },
        ],
      },
    },
    isExternal() || isInternal()
      ? {
          type: "item" as "item",
          element: {
            id: "drawer.upload.new",
            text: t("Upload"),
            icon: <CloudUploadOutlinedIcon />,
            route: "/upload",
          },
        }
      : {
          type: "group" as "group",
          element: {
            id: "drawer.upload",
            title: t("Upload"),
            icon: <CloudUploadOutlinedIcon />,
            items: [
              {
                id: "drawer.upload.new",
                text: t("Sample / URL Upload"),
                route: "/upload",
                nested: true,
              },
              {
                id: "drawer.upload.samples",
                text: t("Uploaded Samples"),
                route: "/upload/sample/list",
                nested: true,
              },
              {
                id: "drawer.upload.urls",
                text: t("Uploaded URLs"),
                route: "/upload/url/list",
                nested: true,
              },
            ],
          },
        },
    // {
    //   type: "item" as "item",
    //   element: {
    //     id: "drawer.phishing_kit",
    //     text: t("Phishing Kits"),
    //     icon: <BuildOutlinedIcon />,
    //     route: "/phishing_kit/list",
    //   },
    // },
    {
      hidden: isSimplified(),
      type: "group" as "group",
      element: {
        id: "drawer.phishing_kit",
        title: t("Phishing Kits"),
        icon: <BuildOutlinedIcon />,
        items: [
          {
            id: "drawer.phishing_kits",
            text: t("Phishing Kits"),
            route: "/phishing_kit/list",
            nested: true,
          },
          {
            id: "drawer.phishing_kit_clusters",
            text: t("Phishing Kit Clusters"),
            route: "/phishing_kit/clusters/list",
            nested: true,
          },
        ],
      },
    },
    !isExternal() && {
      hidden: isSimplified(),
      type: "group" as "group",
      element: {
        id: "drawer.retroHunt",
        title: t("Retro Hunt"),
        icon: <LibraryBooksOutlinedIcon />,
        items:
          isLab() || isStaging() || isLocal()
            ? [
                {
                  id: "drawer.retroHunt.yara",
                  text: t("Yara Rules"),
                  route: "/robin/liveHunt",
                  nested: true,
                },
                {
                  id: "drawer.retroHunt.spam",
                  text: t("Spam Phishing"),
                  route: "/spam/search/list",
                  nested: true,
                },
              ]
            : [
                {
                  id: "drawer.retroHunt.yara",
                  text: t("Yara Rules"),
                  route: "/robin/liveHunt",
                  nested: true,
                },
              ],
      },
    },
    // the current user can see borealis as per ssologin.tsx
    !!localStorage.getItem("currentUser") &&
      !!JSON.parse(localStorage.getItem("currentUser"))["canSeeBorealis"] && {
        type: "item" as "item",
        element: {
          id: "drawer.borealis",
          text: t("drawer.borealis"),
          icon: <FilterHdrOutlinedIcon />,
          route: "/borealisSearch",
        },
      },
    {
      type: "group" as "group",
      element: {
        id: "drawer.policy",
        title: t("Policy"),
        icon: <InfoOutlinedIcon />,
        items: !isExternal()
          ? [
              {
                id: "drawer.policy.compliance",
                text: t("Source Info"),
                route: "/compliance",
                nested: true,
              },
              {
                id: "drawer.policy.terms",
                text: t("Terms and Conditions"),
                route: "/terms_and_conditions",
                nested: true,
              },
            ]
          : [
              {
                id: "drawer.policy.terms",
                text: t("Terms and Conditions"),
                route: "/terms_and_conditions",
                nested: true,
              },
            ],
      },
    },
    {
      type: "item" as "item",
      element: {
        id: "drawer.home",
        text: t("drawer.home"),
        icon: <HomeOutlinedIcon />,
        route: "/home",
      },
    },

    // {
    //   type: "group" as "group",
    //   element: {
    //     id: "drawer.items",
    //     title: t("drawer.items"),
    //     icon: <HomeOutlinedIcon />,
    //     items: [
    //       {
    //         id: "drawer.items.create",
    //         text: t("drawer.items.create"),
    //         icon: <HomeOutlinedIcon />,
    //         route: "/item?create",
    //         nested: true
    //       },
    //       {
    //         id: "drawer.items.edit",
    //         text: t("drawer.items.edit"),
    //         icon: <HomeOutlinedIcon />,
    //         route: "/item?edit",
    //         nested: true
    //       },
    //       {
    //         id: "drawer.items.list",
    //         text: t("drawer.items.list"),
    //         icon: <HomeOutlinedIcon />,
    //         route: "/items",
    //         nested: true
    //       },
    //     ],
    //   },
    // },
    // {
    //   type: "group" as "group",
    //   element: {
    //     id: "drawer.mail",
    //     title: t("drawer.mail"),
    //     icon: <HomeOutlinedIcon />,
    //     items: [
    //       {
    //         id: "drawer.mail.inbox",
    //         text: t("drawer.mail.inbox"),
    //         route: "/mail/inbox",
    //         nested: true
    //       },
    //       {
    //         id: "drawer.mail.sent",
    //         text: t("drawer.mail.sent"),
    //         route: "/mail/sent",
    //         nested: true
    //       },
    //       {
    //         id: "drawer.mail.trash",
    //         text: t("drawer.mail.trash"),
    //         route: "/mail/trash",
    //         nested: true
    //       },
    //     ]
    //   },
    // },
    // {
    //   type: "item" as "item",
    //   element: {
    //     id: "drawer.chat",
    //     text: t("drawer.chat"),
    //     icon: <ChatOutlinedIcon />,
    //     route: "/chat"
    //   },
    // },
    // {
    //   type: "divider" as "divider",
    //   element: null,
    // },
    // {
    //   type: "item" as "item",
    //   element: {
    //     id: "drawer.help",
    //     text: t("drawer.help"),
    //     icon: <HelpOutlineOutlinedIcon />,
    //     route: "/help"
    //   },
    // },
  ];

  /*
  This list defines the list of apps that will be located in the app switcher. 
  Each menu items have the following properties:
    - name: name of the app to link to
    - route: url to the app you link to
    - alt: Alternate one or two letter combinaison if the logos are not found 
    - uri_d: uri for dark mode logo
    - uri_l: uri for light mode logo
    - newWindow: optional force to open in new window
  NOTE: If you don't define any item in the app switcher, the app switcher does not appear
  */
  const APP_SWITCHER_ITEMS = [
    {
      alt: "AL",
      name: "Assemblyline",
      img_d: "/ng/images/al_dark.svg",
      img_l: "/ng/images/al.svg",
      route: "http://10.162.228.5:3001",
    },
    {
      alt: "JH",
      name: "JupyterHub",
      img_d: "/ng/images/jupyter_dark.svg",
      img_l: "/ng/images/jupyter.svg",
      route: "https://jupyhub.u.hogwarts.azure.chi/",
    },
    {
      alt: "SB",
      name: "Spellbook",
      img_d: "/ng/images/spellbook_dark.svg",
      img_l: "/ng/images/spellbook.svg",
      route: "http://10.162.232.5:3000/",
    },
    {
      alt: "D",
      name: "DASI UI",
      img_d: (
        <ViewCompactOutlinedIcon style={{ fontSize: 64, color: "#6d80af" }} />
      ),
      img_l: (
        <ViewCompactOutlinedIcon style={{ fontSize: 64, color: "#4a629e" }} />
      ),
      route: "http://10.162.228.5:3000",
    },
  ];

  /*
  This is the basic user menu, it is a menu that shows up in account avatar popover.
  It has a list of menu items with the following properties:
    - name: text of the menu
    - route: url for the menu
    - icon: optional icon for the menu
  NOTE: if no menu items are provided this menu is hidden
  */
  const USER_MENU_ITEMS = [
    /*  
    {
      name: t("usermenu.account"),
      route: "/account",
      icon: <AccountCircleIcon/>
    },
    {
      name: t("usermenu.settings"),
      route: "/settings"
    },
    */
    {
      name: t("usermenu.logout"),
      route: "/logout",
      icon: <ExitToAppIcon />,
    },
  ];

  /*
  This is the basic administrator menu, it is a menu that shows up under 
  the user menu in the account avatar popover.
  It has a list of menu items with the following properties:
    - name: text of the menu
    - route: url for the menu
    - icon: optional icon for the menu
  NOTE: if no menu items are provided this menu is hidden
  */
  const ADMIN_MENU_ITEMS = [
    /*  
    {
      name: t("admin.users"),
      route: "/admin/users",
      icon: <SupervisorAccountIcon/>
    },
    {
      name: t("adminmenu.config"),
      route: "/admin/configuration"
    } 
    */
  ];

  //This is your App Name that will be displayed in the left drawer and the top navbar
  const appName = "BeAVER";
  // This is your app logo, it should definitely be an svg logo but we will use an icon here instead
  const logo = (
    <Box display="flex">
      <img
        alt=""
        src="/ng/images/beavernew.png"
        style={{ maxWidth: "41px", justifyContent: "center" }}
      />
    </Box>
  );
  // This is your app banner, it should definitely be an svg banner but we will fake one using CSS
  const banner = (
    <Box
      mb={"2rem"}
      display="flex"
      flexDirection="row"
      alignItems="center"
      alignSelf="center"
      justifyContent="center"
    >
      <img
        alt=""
        src="/ng/images/beaver_banner.png"
        className={classes.logo}
      />
      {/* <Box flexWrap="nowrap">{appName}</Box> */}
    </Box>
  );

  const bannerDark = (
    <Box
      mb={"2rem"}
      display="flex"
      flexDirection="row"
      alignItems="center"
      alignSelf="center"
      justifyContent="center"
    >
      <img
        alt=""
        src="/ng/images/beaver_banner_dark.png"
        className={classes.logo}
      />
      {/* <Box flexWrap="nowrap">{appName}</Box> */}
    </Box>
  );

  /*
  This layout provider has the following properties that can be 
  modified to change the appearance of the template. 
  This is a breakdown of the properties:
    - appName: Name of your app, it will show up in the drawer and the top nav bar
    - allowQuickSearch: Allow the quick search bar to be shown in the app
    - allowReset: Allow the reset theming button to be shown in the app
                  NOTE: allowReset should be false on production system as its clearing the localstorage...
    - appIconDark: Small dark mode logo of your app used in drawer and top nav bar
    - appIconLight: Small light mode logo of your app used in drawer and top nav bar
    - bannerDark: Your dark mode app banner, will be use in the loading screen, login and logout pages
    - bannerLight: Your dark mode app banner, will be use in the loading screen, login and logout pages
    - colors: color palette for your app
      - darkPrimary: primary color for dark mode
      - darkSecondary: secondary color for dark mode
      - lightPrimary: primary color for light mode
      - lightSecondary: secondary color for light mode
    - defaultTheme: choose what you app default theme is (light or dark)
    - defaultLayout: either "top" (sticky topbar) or "side" (invisible top bar)
    - defaultDrawerOpen: should the nav drawer be opened by default
    - defaultShowQuickSearch: should the quick search bar be shown by default
    - defaultAutoHideAppbar: should the top bar autohide (only work with "side" layout)
    - topnav: Top navigation bar specific options
      - apps: list of apps items
      - userMenu: list of user menu items
      - userMenuTitle: Title displayed for the user menu
      - adminMenu: list of admin menu items
      - adminMenuTitle: Title displayed for the admin menu
      - quickSearchURI: Uri to redirect to for the quicksearch bar
      - quickSearchParm: Param in which the search query is set for the quicksearch bar
      - themeSelectionUnder: Where does the theme selection menu shows up either 
                             "icon" (as a seperate icon in the top nav bar)
                             "profile" (under the user and admin menus in the profile avatar popover)
    -leftnav: Left Navigation drawer specific options
      elements: list of menu elements in the left navigation drawer
  */
  return {
    appName: appName,
    allowQuickSearch: true,
    allowReset: true,
    appIconDark: logo,
    appIconLight: logo,
    bannerLight: banner,
    bannerDark: bannerDark,
    colors: {
      darkPrimary: "#6d80af",
      darkSecondary: "#d5775c",
      lightPrimary: "#4a629e",
      lightSecondary: "#a60000",
    },
    defaultTheme: "light" as "light",
    defaultLayout: "side" as "side",
    defaultDrawerOpen: false,
    defaultShowQuickSearch: true,
    defaultAutoHideAppbar: false,
    topnav: {
      apps: APP_SWITCHER_ITEMS,
      userMenu: USER_MENU_ITEMS,
      userMenuTitle: t("usermenu"),
      adminMenu: ADMIN_MENU_ITEMS,
      //adminMenuTitle: t("adminmenu"),
      //quickSearchURI: "/search/",
      //quickSearchParam: "q",
      themeSelectionUnder: "profile" as "profile",
    },
    leftnav: {
      elements: MENU_ITEMS,
    },
    useTBSLayout: useTBSLayout,
    breadCrumbRoutes: breadCrumbRoutes,
  };
};

export default useMyLayout;
