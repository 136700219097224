import React from "react";
import Axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Modal,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";
import {
  getResourceLink,
  isExternal,
  isInternal,
  isLab,
  isGeekweek,
  isStaging,
} from "components/beaver/helpers/ServerProperties";
import { getTechnicalIssueEmail } from "components/beaver/components/common/TransWithEmail";

import { Buffer } from "buffer";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export type ScreenshotViewProps = {
  screenshotLinks?: string[];
  dontShowInCard?: boolean;
  md5: string;
  cluster: string;
  height?: string;
};

export function ScreenshotView({
  screenshotLinks,
  dontShowInCard,
  md5,
  cluster,
  height,
}: ScreenshotViewProps) {
  const classes = useStyles();
  const [screenshot, setScreenshot] = React.useState(null);
  const [screenshotAgree, setScreenshotAgree] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const [loadError, setLoadError] = React.useState(null);
  const [agreeToShow, setAgreeToShowBool] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const setAgreeToShow = (agree: boolean) => {
    // set localstorage image Agree value be current date in ms
    if (agree) localStorage.imageAgree = Date.now();
    setAgreeToShowBool(agree);
  };

  React.useEffect(() => {
    if (isExternal()) {
      if (cluster === "restricted") {
        // if external && cluster == 'restricted`: show placeholder saying illicit
        setAgreeToShowBool(true); // assumed that the placeholder will be in the zip
      } else if (cluster) {
        // if external && cluster != 'restricted': show with disclaimer
        setAgreeToShowBool(false);
      } else {
        // if external && !cluster: don't show (show what instead ?)
        // TODO - decide what to show instead and show it, but for now show image
        setAgreeToShowBool(false);
      }
    } else if (isInternal() || isGeekweek()) {
      if (cluster === "restricted") {
        // if internal && cluster == 'restricted: show placeholder saying illicit
        setAgreeToShowBool(true); // assumed that the placeholder will be in the zip
      } else if (cluster) {
        // if internal && is clustered && cluster != 'restricted': show without disclaimer
        setAgreeToShowBool(true);
      } else {
        // if internal && !cluster: show blurred image with disclaimer
        setAgreeToShowBool(false);
      }
    } else if (isLab() || isStaging()) {
      if (cluster === "restricted") {
        // if lab && cluster == 'restricted: show placeholder saying illicit
        setAgreeToShowBool(true); // assumed that the placeholder will be in the zip
      } else if (cluster) {
        // if lab && is clustered && cluster != 'restricted': show without disclaimer
        setAgreeToShowBool(true);
      } else {
        // if lab && !cluster: show blurred image with disclaimer (one week timeout)
        // check date if one week, if so, set the agreetoshow

        let agree = localStorage.imageAgree;
        // if it's not a number, then get rid of it
        if (agree && isNaN(agree)) {
          localStorage.removeItem("imageAgree");
        } else if (agree) {
          // if it is a number, then see of > 7 days, then dump it, otherwise allow the image to be viewed
          if (Date.now() - agree > 1000 * 60 * 60 * 24 * 7) {
            localStorage.removeItem("imageAgree");
            setAgreeToShowBool(false);
          } else {
            // TODO - debug why we need the timeout, shoudl just work.
            setTimeout(() => setAgreeToShowBool(true), 500);
          }
        } else {
          setAgreeToShowBool(false);
        }
      }
    }
  }, [cluster]);

  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};
  const { t } = useTranslation();

  React.useEffect(() => {
    // TODO - REDUX this load if there are complaints about slowness, etc.
    if (
      "mock" !== user.server &&
      screenshotLinks &&
      screenshotLinks.length &&
      agreeToShow
    ) {
      setLoading(true);
      Axios.get(
        getResourceLink(
          [screenshotLinks[index], agreeToShow ? btoa(md5) : ""].join("/"),
          index
        ),
        {
          responseType: "arraybuffer",
          auth:
            user.server && !user.server.includes("localhost")
              ? {
                  username: user ? user.username : null,
                  password: user
                    ? user.onetime
                      ? // eslint-disable-next-line no-eval
                        window
                          // eslint-disable-next-line no-eval
                          .atob(eval(window.atob(user.onetime)))
                          .substr(13)
                      : null
                    : null,
                }
              : null,
        }
      )
        .then((response) => {
          setLoading(false);
          agreeToShow
            ? setScreenshotAgree(
                Buffer.from(response.data, "binary").toString("base64")
              )
            : setScreenshot(
                Buffer.from(response.data, "binary").toString("base64")
              );
        })
        .catch((e) => {
          setLoading(false);
          setLoadError(e.message);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenshotLinks, agreeToShow, index]);

  return !dontShowInCard ? (
    <Card>
      <CardHeader className={classes.ch} title={t("Screenshot")} />{" "}
      <CardContent>
        {screenshotLinks && screenshotLinks.length > 1 ? (
          <Box style={{ textAlign: "center" }}>
            <Button
              color="primary"
              onClick={() => {
                setScreenshot(null);
                setIndex(index - 1);
              }}
              disabled={(!screenshot && !screenshotAgree) || index < 1}
            >
              <ChevronLeftIcon />
            </Button>
            {t("Screenshot")} {index + 1} {t("of")} {screenshotLinks.length}{" "}
            <Button
              color="primary"
              onClick={() => {
                setScreenshot(null);
                setIndex(index + 1);
              }}
              disabled={
                (!screenshot && !screenshotAgree) ||
                index > screenshotLinks.length - 2
              }
            >
              <ChevronRightIcon />
            </Button>
          </Box>
        ) : null}
        {screenshotLinks && screenshotLinks.length ? (
          <Box style={{ textAlign: "center" }}>
            {t("Screenshot date")}
            {": "}
            {screenshotLinks[index].match(/\/\d{8}\//g)[0].replace(/\//g, "")}
          </Box>
        ) : null}
        {loading ? (
          <Box
            style={{
              position: "relative",
              display: "block",
              zIndex: 1,
              textAlign: "center",
            }}
            mt={2}
          >
            {t("Loading Screenshot") + " ..."}
          </Box>
        ) : (
          <ShowScreenshot
            loadError={loadError}
            screenshot={agreeToShow ? screenshotAgree : screenshot}
            screenshotLinks={screenshotLinks}
            mock={"mock" === user.server}
            agreeToShow={agreeToShow}
            setAgreeToShow={setAgreeToShow}
            hideImage={dontShowInCard}
            height={height}
          />
        )}
      </CardContent>
    </Card>
  ) : (
    <>
      <ShowScreenshot
        loadError={loadError}
        screenshot={agreeToShow ? screenshotAgree : screenshot}
        screenshotLinks={screenshotLinks}
        mock={"mock" === user.server}
        agreeToShow={agreeToShow}
        setAgreeToShow={setAgreeToShow}
        hideImage={dontShowInCard}
        height={height}
      />
      <Box style={{ textAlign: "center" }}>
        {screenshotLinks.length > 1
          ? screenshotLinks.length + " " + t("screenshots for this report")
          : null}
      </Box>
    </>
  );
}

export type ShowScreenshotProps = {
  loadError: string;
  screenshot: string;
  screenshotLinks?: string[];
  agreeToShow: boolean;
  setAgreeToShow: Function;
  mock?: boolean;
  hideImage: boolean;
  height?: string;
};

function ShowScreenshot({
  loadError,
  screenshot,
  screenshotLinks,
  agreeToShow,
  setAgreeToShow,
  mock,
  hideImage,
  height,
}: ShowScreenshotProps) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const theme = useTheme();
  const useMobileModalWindow = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {!mock ? (
        <>
          {agreeToShow
            ? null
            : screenshotLinks &&
              !!screenshotLinks.length && (
                <Box
                  p={hideImage ? 0 : 5}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      style={{ textTransform: "uppercase" }}
                    >
                      {t("Disclaimer")}
                    </Typography>
                  </Box>
                  <Box mt={2}>{t("screenshot-disclaimer")}</Box>
                  <Box mt={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        setAgreeToShow(true);
                        e.stopPropagation();
                      }}
                    >
                      {t("I Accept")}
                    </Button>
                  </Box>
                </Box>
              )}
          {!agreeToShow && hideImage ? null : !loadError ? (
            screenshot ? (
              <Box
                style={{
                  position: "relative",
                  display: "block",
                  zIndex: 1,
                  textAlign: "center",
                }}
              >
                <img
                  style={
                    // no height supplied (e.g. url screenshot) ? show width in full space.
                    !height
                      ? { width: "100%", height: "auto" }
                      : {
                          width: "auto",
                          maxHeight: height,
                          maxWidth: "100%",
                        }
                  }
                  src={"data:image/jpeg;base64, " + screenshot}
                  alt={t("Screenshot")}
                  onClick={openModal}
                />
                <br />
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  // send an email by clicking on a link - will be blocked by popup blockers unofortunately
                  href={
                    "mailto:" + getTechnicalIssueEmail() + "?subject=Objectionable Screenshot Report / Rapport de capture d'écran répréhensible&body=url: " + window.location.href
                  }
                >
                  {t("screenshot-report")}
                </Button>
              </Box>
            ) : screenshotLinks && screenshotLinks.length ? (
              agreeToShow && t("Loading Screenshot") + " ..."
            ) : (
              t("No Screenshot")
            )
          ) : (
            t("Error Loading Screenshot") + " : " + loadError
          )}
        </>
      ) : (
        <>
          <img
            style={{ width: "100%", height: "auto" }}
            src="/ng/images/beaverng.jpg"
            alt={t("Screenshot")}
          />
        </>
      )}
        <Modal
          open={open}
          onClose={closeModal}
        >
          <Box
            style={{
              display: 'block',
              margin: '7vh auto auto auto',
              maxHeight: '80vh',
              maxWidth: '100%',
              width: 'fit-content',
              padding: useMobileModalWindow ? '2vw': 'none',
              height: useMobileModalWindow ? 'auto' : '80vh'
            }}
          >
            <img
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: useMobileModalWindow ? 'auto': '100%',
                maxHeight: '80vh',
                maxWidth: '100%'
              }}
              src={"data:image/jpeg;base64, " + screenshot}
              alt={t("Screenshot")}
            />
          </Box>
        </Modal>
    </>
  );
}
