import React, { useEffect } from "react";
import _ from "lodash";
import MD5 from "crypto-js/md5";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";
import { fetchClusters, clusterSelector } from "slices/clusters";
import { ScreenshotView } from "./ScreenshotView";
import {
  DownloadRawURLReport,
  DownloadURLReport,
  isLab,
  isStaging,
} from "../../../../helpers/ServerProperties";

import {
  urlReport,
  URLReportSections,
} from "../../../../models/report/url/urlReport";

import {
  hasConnectionSuccess,
  hasKangarooError,
  hasStatusCodeSuccess,
  urlStatus,
} from "components/beaver/models/report/url/urlSummary";

import { SimpleTableView } from "../../common/SimpleTableView";

import { FaviconView } from "./FaviconView";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ClipboardValue, ClipboardButton } from "../../common/ClipboardButton";
import { DecodeButton } from "../../common/DecodeButton";

import { isSimplified } from "components/beaver/helpers/ServerProperties";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

export type URLSummaryType = {
  report: urlReport;
  screenshotLinks?: string[];
  iconResources?: string[];
  setShow: Function;
  dom?: string;
  setFaviconLoadError: Function;
};

export function URLSummary({
  report,
  screenshotLinks,
  iconResources,
  setShow,
  dom,
  setFaviconLoadError,
}: URLSummaryType) {
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { sources } = useSelector(sourceSelector);
  const { clusters } = useSelector(clusterSelector);
  const { t } = useTranslation();
  const [showSources, setShowSources] = React.useState(false);
  // TODO what if array empty ?
  const [firstSeen, setFirstSeen] = React.useState(null);
  const [lastSeen, setLastSeen] = React.useState(null);
  const [sortedSourceList, setSortedSourceList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  useEffect(() => {
    dispatch(fetchClusters(clusters));
  }, [dispatch, clusters]);

  useEffect(() => {
    const sortedSources = _.cloneDeep(report.sources);
    sortedSources.sort((a, b) => a.receivedDate - b.receivedDate);
    setFirstSeen(_.first(sortedSources));
    setLastSeen(_.last(sortedSources));
    setSortedSourceList(sortedSources.reverse());
  }, [report]);

  return (
    <Card>
      <CardHeader className={classes.ch} title={t("Summary")} />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>{t("First Seen")}</TableCell>
                {firstSeen ? (
                  <TableCell>
                    {firstSeen.receivedDate}&nbsp;
                    {sources && sources[firstSeen.source]
                      ? sources[firstSeen.source].displayString
                      : firstSeen.source}{" "}
                    &nbsp;
                    {firstSeen.isTransformed || firstSeen.isRedirection
                      ? "Inferred, not found directly from this source"
                      : ""}
                  </TableCell>
                ) : (
                  <TableCell>{t("Loading")} ...</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell>{t("Last Seen")}</TableCell>
                {lastSeen ? (
                  <TableCell>
                    {lastSeen.receivedDate}
                    &nbsp;
                    {sources && sources[lastSeen.source]
                      ? sources[lastSeen.source].displayString
                      : lastSeen.source}{" "}
                    &nbsp;
                    {lastSeen.isTransformed || lastSeen.isRedirection
                      ? "Inferred, not found directly from this source"
                      : ""}
                  </TableCell>
                ) : (
                  <TableCell>{t("Loading")} ...</TableCell>
                )}
              </TableRow>
              {!report.spiderReport &&
                !!report.status?.request?.lastStatusCode && (
                  <TableRow>
                    <TableCell>{t("Status Code")}</TableCell>
                    <TableCell>
                      {report.status.request.lastStatusCode}
                    </TableCell>
                  </TableRow>
                )}
              {!report.spiderReport && !!report.status && (
                <TableRow>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>
                    <GetStatusString
                      status={report.status}
                      statusCode={
                        report?.status?.request?.lastStatusCode
                          ? report.status.request.lastStatusCode
                          : null
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              {report.urlCluster?.value ? (
                <>
                  <TableRow>
                    <TableCell>{t("Cluster")}</TableCell>
                    <TableCell>
                      {!isSimplified() &&
                      report.urlCluster?.value.clusterName ? (
                        <Badge
                          badgeContent={
                            clusters &&
                            clusters[report.urlCluster?.value.clusterName]
                              ? clusters[report.urlCluster?.value.clusterName]
                                  .total
                              : null
                          }
                          color="secondary"
                          max={999}
                        >
                          <Link
                            to={[
                              "/cluster",
                              report.urlCluster?.value.clusterName,
                              "report",
                            ].join("/")}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              color="primary"
                              clickable
                              size="small"
                              label={report.urlCluster?.value.clusterName}
                            />
                          </Link>
                        </Badge>
                      ) : isSimplified() ? (
                        <Badge
                          badgeContent={
                            clusters &&
                            clusters[report.urlCluster?.value.clusterName]
                              ? clusters[report.urlCluster?.value.clusterName]
                                  .total
                              : null
                          }
                          color="secondary"
                          max={999}
                        >
                          <Chip
                            color="primary"
                            size="small"
                            label={report.urlCluster?.value.clusterName}
                          />
                        </Badge>
                      ) : (
                        t("Unknown")
                      )}
                    </TableCell>
                  </TableRow>
                  {!!report?.urlCluster?.value?.subclusterType && (
                    <TableRow>
                      <TableCell>{t("Subcluster")}</TableCell>
                      <TableCell>
                        {/* TODO - not part of Geekweek 2023, but need to fix cluster report with subcluster and make this clickable if not simplified */}
                        <Badge
                          badgeContent={
                            clusters &&
                            clusters[report.urlCluster?.value.subclusterType]
                              ? clusters[
                                  report.urlCluster?.value.subclusterType
                                ].total
                              : null
                          }
                          color="secondary"
                          max={999}
                        >
                          <Chip
                            color="primary"
                            size="small"
                            label={report.urlCluster?.value.subclusterType}
                          />
                        </Badge>
                      </TableCell>
                    </TableRow>
                  )}
                  {!!report?.urlCluster?.value?.clusterCountry && (
                    <TableRow>
                      <TableCell>{t("Clustered Country")}</TableCell>
                      <TableCell>
                        {report.urlCluster.value.clusterCountry}
                      </TableCell>
                    </TableRow>
                  )}
                  {!!report?.urlCluster?.value?.sector && (
                    <TableRow>
                      <TableCell>{t("Sector")}</TableCell>
                      <TableCell>
                        {report.urlCluster.value.sector +
                          (report.urlCluster.value.sectorString &&
                          report.urlCluster.value.sector.toUpperCase() !==
                            report.urlCluster.value.sectorString.toUpperCase()
                            ? " (" + report.urlCluster.value.sectorString + ")"
                            : "")}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>{t("Cluster Category")}</TableCell>
                    <TableCell>
                      {report.urlCluster.value.clusterCategory}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Classifier")}</TableCell>
                    <TableCell>{report.urlCluster.value.classifier}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t("Cluster Destination analyzed as")}
                    </TableCell>
                    <TableCell>
                      {report.urlCluster.value.clusterDestination}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell>{t("Cluster")}</TableCell>
                  <TableCell>{t("Unknown")}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  {t("Source")}
                  {report.sources && report.sources.length > 1
                    ? "s"
                    : null}{" "}
                  {report.sources && report.sources.length
                    ? "(" + report.sources.length + ")"
                    : null}
                </TableCell>
                <TableCell>
                  {sortedSourceList && sortedSourceList.length ? (
                    showSources ? (
                      <>
                        {sortedSourceList && sortedSourceList.length > 5 ? (
                          <SimpleTableView
                            headers={[
                              { title: t("Source"), field: "source" },
                              { title: t("Recieved Date"), field: "date" },
                            ]}
                            data={sortedSourceList.map((s) => ({
                              source:
                                sources && sources[s.source]
                                  ? sources[s.source].displayString
                                  : s.source,
                              date: s.receivedDate,
                            }))}
                          />
                        ) : (
                          <TableContainer>
                            <Table size="small">
                              <TableBody>
                                {sortedSourceList.map((s, i) => (
                                  <TableRow key={i}>
                                    <TableCell>
                                      {sources && sources[s.source]
                                        ? sources[s.source].displayString
                                        : s.source}
                                    </TableCell>
                                    <TableCell>{s.receivedDate}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}{" "}
                      </>
                    ) : (
                      <>
                        {Array.from(
                          new Set(
                            report.sources.map((s) =>
                              sources && sources[s.source]
                                ? sources[s.source].displayString
                                : s.source
                            )
                          )
                        ).join(", ")}
                      </>
                    )
                  ) : report.status.request ? (
                    report.status.request.source
                  ) : null}
                  <Button
                    variant="contained"
                    size="small"
                    style={{ marginLeft: 15 }}
                    color="primary"
                    onClick={() => setShowSources(!showSources)}
                  >
                    {showSources
                      ? t("Hide Source Details")
                      : t("Show Source Details")}
                  </Button>
                </TableCell>
              </TableRow>
              {(isLab() || isStaging()) &&
              firstSeen &&
              report.status.request &&
              report.status.request.lastSuccessOn ? (
                <TableRow>
                  <TableCell>{t("Download")}</TableCell>
                  <TableCell>
                    <Button
                      href={DownloadURLReport(report.md5)}
                      variant="contained"
                      color="primary"
                      size="small"
                      target="_blank"
                    >
                      {t("Download reports")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 15 }}
                      size="small"
                      onClick={() => setOpen(true)}
                    >
                      {t("Download RAW results")}
                    </Button>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                      <DialogTitle>{t("Download Confirmation")}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          <Typography variant="subtitle1">
                            {t(
                              "WARNING - The file you are looking to download may contain malware."
                            )}
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpen(false)}
                          color="primary"
                          variant="contained"
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "orange",
                            color: `${theme.palette.warning}`,
                          }}
                          variant="contained"
                          onClick={() => setOpen(false)}
                          target="_blank"
                          href={DownloadRawURLReport(
                            report.md5,
                            report.status.request &&
                              report.status.request.lastSuccessOn
                              ? report.status.request.lastSuccessOn.toString()
                              : null,
                            report.status.request &&
                              report.status.request.source
                              ? report.status.request.source
                              : null
                          )}
                        >
                          {t("Download")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>

        {report.spiderReport ? (
          <Box mt={3}>
            <Card>
              <CardHeader className={classes.ch} title={t("Spider Report")} />
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={screenshotLinks && screenshotLinks.length ? 9 : 12}
                  >
                    <TableContainer>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("Fetching Date")}</TableCell>
                            <TableCell>
                              {report.spiderReport.creationDate}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Engine")}</TableCell>
                            <TableCell>
                              {report.spiderReport.engineName}{" "}
                              {report.spiderReport.engineVersion}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Requested URL")}</TableCell>
                            <TableCell className={classes.breakword}>
                              <>
                                <LinkWrap noBeaverLink>
                                  {
                                    report.spiderReport.communication
                                      .requestedURL
                                  }
                                </LinkWrap>

                                <DecodeButton>
                                  <ClipboardButton
                                    value={
                                      report.spiderReport.communication
                                        .requestedURL
                                    }
                                  />
                                </DecodeButton>
                              </>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Requested URL MD5")}</TableCell>
                            <TableCell>
                              <ClipboardValue value={report.md5} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Actual URL")}</TableCell>
                            <TableCell className={classes.breakword}>
                              <>
                                <LinkWrap noBeaverLink>
                                  {report.spiderReport.communication.actualURL}
                                </LinkWrap>
                                <DecodeButton>
                                  <ClipboardButton
                                    value={
                                      report.spiderReport.communication
                                        .actualURL
                                    }
                                  />
                                </DecodeButton>
                              </>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Actual URL MD5")}</TableCell>
                            <TableCell>
                              <ClipboardValue
                                value={MD5(
                                  report.spiderReport.communication.actualURL
                                ).toString()}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Status Code")}</TableCell>
                            <TableCell>
                              {report.spiderReport.communication.statusCode}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Status")}</TableCell>
                            <TableCell>
                              <GetStatusString
                                status={report.status}
                                statusCode={
                                  report.spiderReport.communication.statusCode
                                }
                              />
                            </TableCell>
                          </TableRow>
                          {iconResources && iconResources.length ? (
                            <TableRow>
                              <TableCell>{t("Favicon")}</TableCell>
                              <TableCell>
                                <Box
                                  onClick={() =>
                                    setShow(URLReportSections.FavIcon)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaviconView
                                    setFaviconLoadError={setFaviconLoadError}
                                    faviconLinks={iconResources}
                                    showMulti
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : null}
                          {report.urlTitle ? (
                            <TableRow>
                              <TableCell>{t("Title")}</TableCell>
                              <TableCell className={classes.breakword}>
                                <Box
                                  onClick={() =>
                                    setShow(URLReportSections.Title)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {report.urlTitle}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : null}
                          {dom &&
                          dom.match(
                            /<[tT][iI][tT][lL][eE]>.*<\/[tT][iI][tT][lL][eE]>/
                          ) ? (
                            <TableRow>
                              <TableCell>
                                {t("Title") + " (" + t("from DOM") + ")"}
                              </TableCell>
                              <TableCell>
                                <Box
                                  onClick={() => setShow(URLReportSections.Dom)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {dom
                                    .match(
                                      /<[tT][iI][tT][lL][eE]>.*<\/[tT][iI][tT][lL][eE]>/
                                    )[0]
                                    .replace(/<\/?[tT][iI][tT][lL][eE]>/g, "")}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {report.spiderReport.communication.requestedURL.match(
                      /\/\/[^/]*\//
                    ) ? (
                      <Box mt={1}>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={[
                            "/domain",
                            report.spiderReport.communication.requestedURL
                              .match(/\/\/[^/]*\//)[0]
                              .replace(/\//g, ""),
                            "report",
                          ].join("/")}
                        >
                          <Button>
                            <SearchIcon />
                            {t("More about") +
                              " " +
                              report.spiderReport.communication.requestedURL
                                .match(/\/\/[^/]*\//)[0]
                                .replace(/\//g, "")}
                          </Button>
                        </Link>
                      </Box>
                    ) : null}
                  </Grid>
                  {screenshotLinks && screenshotLinks.length ? (
                    <Grid item xs={12} md={3}>
                      <Box mb={2} ml={2} style={{ cursor: "pointer" }}>
                        <ScreenshotView
                          screenshotLinks={screenshotLinks}
                          dontShowInCard={true}
                          md5={report.md5}
                          cluster={report.status.clusterName}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ) : null}
        {/* TODO - add "Download reports", "Download RAW results" buttons*/}
      </CardContent>
    </Card>
  );
}

export function GetStatusString({
  status,
  statusCode,
}: {
  status: urlStatus;
  statusCode: string | number;
}) {
  if ("LOCKED" === status.queueStatus) {
    return (
      <span>
        <strong>Locked</strong>, should be crawled in a few minutes
      </span>
    );
  } else if ("LOCKED_FOREVER" === status.queueStatus) {
    return (
      <span>
        <strong>Locked for too long</strong>, needs human intervention
      </span>
    );
  } else if ("LOCKED_WITH_ERROR" === status.queueStatus) {
    return (
      <span>
        <strong>Locked with an error</strong>, needs human intervention
      </span>
    );
  } else if ("QUEUED" === status.queueStatus) {
    return (
      <span>
        <strong>Queued</strong>, should be crawled soon
      </span>
    );
  }
  //comes from the status.scala.html in legacy beaver
  if (status.isCrawled && hasKangarooError(status))
    return <span>Error during crawl</span>;
  else if (status.isCrawled && !hasConnectionSuccess(status))
    return <span>Crawled, but no connection possible</span>;
  else if (
    status.isCrawled &&
    (hasStatusCodeSuccess(status) || (+statusCode >= 200 && +statusCode <= 299))
  )
    return (
      <span>
        Crawled with{" "}
        <span style={{ color: "green", fontWeight: "bold" }}>2xx</span> status
        code
      </span>
    );
  else if (status.isCrawled && !hasStatusCodeSuccess(status))
    return statusCode ? (
      <span>
        Crawled with{" "}
        <span style={{ color: "red", fontWeight: "bold" }}>{statusCode}</span>{" "}
        status code
      </span>
    ) : (
      <>Crawled with unknown status code</>
    );
  else if (status?.url?.error) {
    return (
      <span>
        <strong>Error during crawl</strong>, needs human intervention
      </span>
    );
  } else
    return (
      <span style={{ color: "grey" }}>
        In the database, but never crawled directly
      </span>
    );
}
