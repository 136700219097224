import React from "react";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";
import { useStyles } from "../../common/sharedStyles";
import { SimpleTableCardView } from "../../common/SimpleTableView";

import { getDomainFromURL } from "../../../../helpers/DomainUtil";

import { PhishingItemReportData } from "../../../../models/report/phishing/phishingItemModel";
import { useTranslation } from "react-i18next";
import { ClipboardButton, ClipboardValue } from "../../common/ClipboardButton";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";
import {
  fetchPhishingClusters,
  phishingClusterSelector,
} from "slices/phishingclusters";
import {
  DownloadPhishingKit,
  isLab,
  isStaging,
} from "../../../../helpers/ServerProperties";

const md5 = require("md5");

export type PhishingItemReportProps = {
  data: PhishingItemReportData;
  testing?: boolean;
};

// TODO -get a better Phishing Kit example in the mocks pls - e.g. https://beaver.ccirc.lab/phishing_kit/08431271d83a77193c8071132de27a49/report has multiple domains, multiple clusters / subcluster

export function PhishingItemReport({ data, testing }: PhishingItemReportProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const domain = getDomainFromURL(data.artifact.originURL);

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);
  const { phishingclusters } = useSelector(phishingClusterSelector);

  React.useEffect(() => {
    if (!testing) {
      dispatch(fetchSources(sources));
    }
  }, [dispatch, sources, testing]);

  React.useEffect(() => {
    if (!testing) {
      dispatch(fetchPhishingClusters(phishingclusters));
    }
  }, [dispatch, phishingclusters, testing]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={11} lg={7}>
          <Card>
            <CardHeader
              title={t("Phishing Kit Details")}
              className={classes.ch}
            />
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>MD5</TableCell>
                      <TableCell
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Container style={{ paddingLeft: 0 }}>
                          <ClipboardValue value={data.artifact.md5} />
                        </Container>
                        {isLab() || isStaging() ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            href={DownloadPhishingKit(data.artifact.md5)}
                            target="_blank"
                          >
                            {t("Download")}
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Received Date")}</TableCell>
                      <TableCell>{data.artifact.receivedDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Cyber Tool Type")}</TableCell>
                      <TableCell>{data.artifact.cyberToolType}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Source")}</TableCell>
                      <TableCell>
                        {sources && sources[data.artifact.source]
                          ? sources[data.artifact.source].displayString
                          : data.artifact.source}
                      </TableCell>
                    </TableRow>
                    {domain ? (
                      <TableRow>
                        <TableCell>{t("Origin Domain")}</TableCell>
                        <TableCell>
                          {domain ? (
                            <>
                              <Link
                                to={["/domain", domain, "report"].join("/")}
                              >
                                <LinkWrap>{domain}</LinkWrap>
                              </Link>
                              <ClipboardButton value={domain} />
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {data.artifact.originURL ? (
                      <TableRow>
                        <TableCell>{t("Origin URL")}</TableCell>
                        <TableCell>
                          {data.artifact.originURL ? (
                            <>
                              <Link
                                to={[
                                  "/url",
                                  md5(data.artifact.originURL),
                                  "report",
                                ].join("/")}
                              >
                                <LinkWrap>{data.artifact.originURL}</LinkWrap>
                              </Link>
                              <ClipboardButton
                                value={data.artifact.originURL}
                              />
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    <TableRow>
                      <TableCell>{t("Original Zip MD5")}</TableCell>
                      <TableCell>
                        <ClipboardValue value={data.artifact.originalZipMd5} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("File Name")}</TableCell>
                      <TableCell>{data.artifact.fileName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Type")}</TableCell>
                      <TableCell>{data.artifact.type}</TableCell>
                    </TableRow>
                    {!!data?.detailsMap?.cluster_name ? (
                      <TableRow>
                        <TableCell>{t("Cluster")}</TableCell>
                        <TableCell>
                          <Badge
                            badgeContent={
                              phishingclusters &&
                              phishingclusters[data.detailsMap.cluster_name]
                                ? phishingclusters[data.detailsMap.cluster_name]
                                    .total
                                : null
                            }
                            color="secondary"
                            max={999}
                          >
                            <Link
                              to={[
                                "/phishing_kit/" +
                                  data.detailsMap.cluster_name +
                                  "/report",
                              ].join("/")}
                              style={{ textDecoration: "none" }}
                            >
                              <Chip
                                color="primary"
                                clickable
                                size="small"
                                label={data.detailsMap.cluster_name}
                              />
                            </Link>
                          </Badge>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {!!data?.detailsMap?.subcluster_name ? (
                      <TableRow>
                        <TableCell>{t("Subcluster")}</TableCell>
                        <TableCell>
                          <Badge
                            badgeContent={
                              phishingclusters &&
                              phishingclusters[data.detailsMap.subcluster_name]
                                ? phishingclusters[
                                    data.detailsMap.subcluster_name
                                  ].total
                                : null
                            }
                            color="secondary"
                            max={999}
                          >
                            <Link
                              to={[
                                "/phishing_kit/" +
                                  data.detailsMap.subcluster_name +
                                  "/report",
                              ].join("/")}
                              style={{ textDecoration: "none" }}
                            >
                              <Chip
                                color="primary"
                                clickable
                                size="small"
                                label={data.detailsMap.subcluster_name}
                              />
                            </Link>
                          </Badge>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {data.domainsList ? (
                      <TableRow>
                        <TableCell>{t("Domain(s)")}</TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                          >
                            {data.domainsList.map((u, i) => {
                              let d = getDomainFromURL(u);
                              return (
                                <Box key={i} mr={1}>
                                  <>
                                    <Link
                                      to={["/domain", d, "report"].join("/")}
                                    >
                                      <LinkWrap>{d}</LinkWrap>
                                    </Link>
                                    <ClipboardButton value={d} />
                                  </>
                                </Box>
                              );
                            })}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {data.domainsList ? (
                      <TableRow>
                        <TableCell>{t("URLs")}</TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                          >
                            {data.domainsList.map((d, i) => (
                              <Box key={i} mr={1}>
                                <>
                                  <Link
                                    to={["/url", md5(d), "report"].join("/")}
                                  >
                                    <LinkWrap>{d}</LinkWrap>
                                  </Link>
                                  <ClipboardButton value={d} />
                                </>
                              </Box>
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mt={2}>
        <SimpleTableCardView
          testing={testing}
          title={t("Phishing Kit Resources")}
          data={cloneDeep(data.artifact.resources)}
          headers={[
            {
              title: t("Resource MD5"),
              field: "md5",
            },
            {
              title: t("File Name"),
              field: "filename",
            },
            {
              title: t("Extension"),
              field: "extension",
            },
            { title: t("Path"), field: "path" },
          ]}
        />
      </Box>
    </Box>
  );
}
