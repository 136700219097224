import React from "react";
import { Link } from "react-router-dom";
import { LinkWrap } from "../report/common/LinkWrap";
import {
  Badge,
  Box,
  Card,
  CardHeader,
  CardContent,
  Chip,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "../report/common/sharedStyles";
import _ from "lodash";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import {
  ClipboardValue,
  ClipboardButton,
} from "../report/common/ClipboardButton";
import { HelpToolTip, HelpButton } from "../report/common/HelpButton";
import {
  BorealisModulesHelp,
  BorealisHelp,
} from "components/beaver/help/BorealisHelp";
import { downloadStringAsFile } from "components/beaver/helpers/downloader";
import {
  getModuleCount,
  getModuleCountOrStringForChip,
} from "components/beaver/models/borealis/borealisModel";
import { ModuleDataView } from "./ModuleDataView";
import { Flag } from "../../helpers/Flag";
import { BorealisDNSReolution } from "./BorealisDNSReolution";

type BorealisReportError = {
  code: number;
  description: string;
  homeRef: string;
  reasonPhrase: string;
  uri: string;
  requestURL: string;
};

// url has domain, url, ips; ip has; domain has domain and ips
type BorealisReportType = {
  domain?: string;
  url?: string;
  ips: string[];
  results: {
    resolver?: string;
    resolvedDomains: {
      ip: string;
      domain?: string;
      date: string;
      addedOn: number;
    }[];
    status: string;
  }[];
  // this is an object where key is the module type and the value is likely subject to change but is an array of objects
  modules: { [module: string]: object[] };
  requestURL: string;
  // these three appear when there is an error
  error?: string;
  message?: string;
  status_code?: number;
};

// TODO write tests with sample jsons
export function BorealisReport({
  report,
  error,
  startMinimized,
}: {
  report: BorealisReportType;
  error: BorealisReportError;
  startMinimized?: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [query, setQuery] = React.useState(null);
  const [modules, setModules] = React.useState(null);
  const [mode, setMode] = React.useState(null);
  //support to minimize (just show card header) and control from other place (search results)
  const [minimized, setMinimized] = React.useState(startMinimized);

  // we need to parse the header based on the query, modules and mode and throw it in state
  React.useEffect(() => {
    const url = new URL(report.requestURL);
    setQuery(url.searchParams.get('query'));
    setModules(url.searchParams.get('modules'));
    setMode(url.searchParams.get('mode'));
  }, [report.requestURL]);

  if (!!error) {
    // show error page
    return (
      <Box my={2}>
        <Card>
          <CardHeader
            title={t("Borealis Report Error") + " - " + query}
            className={classes.ch}
          />
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(report)
                    .filter((r) => r !== "requestURL")
                    .map((r, i) => (
                      <TableRow key={i}>
                        <TableCell>{t(_.startCase(r))}</TableCell>
                        <TableCell>{report[r]}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    );
  } else {
    // show individual report pages
    return (
      <Box my={2}>
        <Card>
          <CardHeader
            onClick={() => setMinimized(!minimized)}
            style={{ cursor: "pointer" }}
            title={
              <>
                {t("Borealis Report") +
                  (report.error ? " " + t("Error") : "") +
                  " - " +
                  (report["url"]
                    ? report["url"]
                    : report["domain"]
                    ? report["domain"]
                    : report["ips"] && report["ips"][0]
                    ? report["ips"][0]
                    : query)}
                {report?.modules &&
                  report?.modules["MAXMIND"] &&
                  report?.modules["MAXMIND"].length && (
                    <Flag
                      style={{ maxHeight: "0.8em", marginLeft: "0.5em" }}
                      countryCode={
                        report.modules["MAXMIND"][0]["ipLocation"][
                          "countryCode"
                        ]
                      }
                    />
                  )}
              </>
            }
            className={report.error ? classes.cherror : classes.ch}
            action={
              <div style={{marginTop: "8px", marginRight: "8px"}}>
                {!report.error && (
                  <DownloadIconButton report={report} query={query} />
                )}
                <ClipboardButton
                  value={ 
                    window.location.origin + "/ng/borealisReport?" + (new URLSearchParams({query: query, modules: modules, mode: mode})).toString()
                  }
                  stopPropagation
                />
                <Tooltip title={t("Open in new tab")} arrow placement="top">
                  <IconButton
                    size="small" 
                    onClick={(e) => {
                      window.open(
                        window.location.origin + "/ng/borealisReport?" + (new URLSearchParams({query: query, modules: modules, mode: mode})).toString(),
                        "_blank"
                      );
                      e.stopPropagation();
                    }}
                  >
                    <OpenInNewOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={minimized ? t("Show Report") : t("Hide Report")}
                  arrow
                  placement="top"
                >
                  <IconButton size="small" onClick={() => setMinimized(!minimized)}>
                    {minimized ? (
                      <ExpandMoreOutlinedIcon />
                    ) : (
                      <ExpandLessOutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            }
          />

          {!minimized && (
            <CardContent>
              <BorealisReportView
                // just in case the modules are empty, e.g. when there is a 400 error
                report={{ results: [], modules: {}, ...report }}
                query={query}
                modules={modules ? modules : ""}
                hideDownloadInSummary
              />
            </CardContent>
          )}
        </Card>
      </Box>
    );
  }
}

function DownloadIconButton({ report, query }: { report: any; query: string }) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Download JSON")} arrow placement="top">
      <IconButton
        size="small" 
        onClick={(e) => {
          downloadStringAsFile(
            JSON.stringify(report, null, 2),
            "borealis_" + query + ".json"
          );
          e.stopPropagation();
        }}
      >
        <GetAppOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

export function BorealisReportView({
  report,
  query,
  modules,
  hideDownloadInSummary,
}: {
  report: BorealisReportType;
  query: string;
  modules: string;
  hideDownloadInSummary?: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  // we need the length of this too, so we need to put in state
  const [dnsResolutionList, setDnsResolutionList] = React.useState([]);

  React.useEffect(() => {
    // for each of the resolved domains in the results, we need to
    // add the resolver and status to them and then flatten everything out
    // so we have one nice array of objects to show
    setDnsResolutionList(
      report.results
        .map((r) =>
          r.resolvedDomains.map((s) => ({
            ...s,
            resolver: r.resolver,
            status: r.status,
          }))
        )
        .flat(1)
    );
  }, [report.results]);

  return (
    <Box>
      {report.error ? (
        <Card>
          <CardHeader title={t("Error")} className={classes.cherror} />
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(report)
                    .filter(
                      (a) => !["results", "modules", "requestURL"].includes(a)
                    )
                    .map((e, i) => (
                      <TableRow key={i}>
                        <TableCell>{t(_.startCase(e))}</TableCell>
                        <TableCell>{JSON.stringify(report[e])}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader
            title={t("Summary")}
            className={classes.ch}
            action={
              <div style={{marginTop: "8px", marginRight: "8px"}}>
                {!hideDownloadInSummary ? (
                  <DownloadIconButton report={report} query={query} />
                ) : null}
                <HelpButton
                  help={<BorealisHelp />}
                  title={t("Borealis Report")}
                />
              </div>
            }
          />
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {!!report.url && (
                    <TableRow>
                      <TableCell>{t("URL")}</TableCell>
                      <TableCell>
                        {query === report.url ? (
                          <ClipboardValue value={report.url} />
                        ) : (
                          <>
                            <Link to={["/url", report.url, "report"].join("/")}>
                              <LinkWrap>{report.url}</LinkWrap>
                            </Link>
                            <ClipboardButton value={report.url} />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {!!report.domain && (
                    <TableRow>
                      <TableCell>{t("Domain")}</TableCell>
                      <TableCell>
                        {query === report.domain ? (
                          <ClipboardValue value={report.domain} />
                        ) : (
                          <>
                            <Link
                              to={["/domain", report.domain, "report"].join(
                                "/"
                              )}
                            >
                              <LinkWrap>{report.domain}</LinkWrap>
                            </Link>
                            <ClipboardButton value={report.domain} />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      {!!report.ips && report.ips.length > 1 ? (
                        <Badge
                          badgeContent={report.ips.length}
                          color="secondary"
                        >
                          {t("IP Addresses")}
                        </Badge>
                      ) : (
                        t("IP Address")
                      )}
                    </TableCell>
                    <TableCell>
                      {!!report.ips && !!report.ips.length
                        ? report.ips.map((ip, i) => (
                            <React.Fragment key={"ip_" + i}>
                              {query === ip ? (
                                <ClipboardValue value={ip} />
                              ) : (
                                <>
                                  <Link to={["/ip", ip, "report"].join("/")}>
                                    <LinkWrap>{ip}</LinkWrap>
                                  </Link>
                                  <ClipboardButton value={ip} />
                                </>
                              )}
                            </React.Fragment>
                          ))
                        : t("None")}
                    </TableCell>
                  </TableRow>
                  {!!report.domain && (
                    <TableRow>
                      <TableCell>
                        <Badge
                          badgeContent={
                            report.results.map((r) => r.resolver).length
                          }
                          color="secondary"
                        >
                          {t("Resolved Domain Sources")}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        {report.results
                          .map((r) => (r.resolver ? r.resolver : "N/A"))
                          .sort()
                          .join(", ")}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <Badge
                        badgeContent={
                          Object.keys(report.modules).filter(
                            (f) =>
                              "BEAVER" !== f &&
                              getModuleCount(report.modules[f]) !== 0
                          ).length
                        }
                        color="secondary"
                      >
                        {/* shows only modules in response with data instead, e.g. SPUR might be empty array, or VIRUSTOTAL might be empty object */}
                        {t("Modules in Response with Data")}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {Object.keys(report.modules).filter(
                        (f) =>
                          "BEAVER" !== f &&
                          getModuleCount(report.modules[f]) !== 0
                      ).length
                        ? Object.keys(report.modules)
                            .filter(
                              (f) =>
                                "BEAVER" !== f &&
                                getModuleCount(report.modules[f]) !== 0
                            )
                            .sort()
                            .join(", ")
                        : t("None")}
                    </TableCell>
                  </TableRow>
                  {/* here we will show any useful infomration */}
                  {!!report?.modules["ALPHABETSOUP"] &&
                    !!report.modules["ALPHABETSOUP"]["isDGA"] && (
                      <TableRow>
                        <TableCell>
                          {t("ALPHABETSOUP")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.ALPHABETSOUP[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {t("Flagged as Domain Generation Algorithm")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["STONEWALL"] &&
                    "NA" !== report.modules["STONEWALL"]["reason"] && (
                      <TableRow>
                        <TableCell>
                          {t("STONEWALL")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.STONEWALL[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {report.modules["STONEWALL"]["decision"] +
                            " / " +
                            report.modules["STONEWALL"]["reason"] +
                            (report.modules["STONEWALL"]["filename"]
                              ? " / " + report.modules["STONEWALL"]["filename"]
                              : "")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["MOOSE"] &&
                    !!report.modules["MOOSE"].length && (
                      <TableRow>
                        <TableCell>
                          {t("MOOSE") + " " + t("Families")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.MOOSE[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {Array.from(
                            new Set(
                              report.modules["MOOSE"].map((f) => f["family"])
                            )
                          ).join(", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["AUWL"] &&
                    !!report.modules["AUWL"].length && (
                      <TableRow>
                        <TableCell>
                          {t("AUWL") + " " + t("Clusters")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.AUWL[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {Array.from(
                            new Set(
                              report.modules["AUWL"].map(
                                (f) =>
                                  f["clusterName"] +
                                  " / " +
                                  f["clusterCategory"]
                              )
                            )
                          ).join(", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["TOP1MILLION"] &&
                    !!report.modules["TOP1MILLION"].filter((t) => t["inList"])
                      .length && (
                      <TableRow>
                        <TableCell>
                          {t("TOP1MILLION")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.TOP1MILLION[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {report.modules["TOP1MILLION"]
                            .filter((t) => t["inList"])
                            .sort((a, b) => a["rank"] - b["rank"])
                            .map(
                              (t) =>
                                "#" +
                                t["rank"] +
                                " in " +
                                t["listName"] +
                                " (" +
                                t["lastIn"] +
                                ")"
                            )
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["SAFEBROWSING"] &&
                    !!report.modules["SAFEBROWSING"]
                      .map((s) => s["decision"])
                      .filter((s) => !!s).length && (
                      <TableRow>
                        <TableCell>
                          {t("SAFEBROWSING") + " " + t("Decision")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.SAFEBROWSING[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {report.modules["SAFEBROWSING"]
                            .map((s) => s["decision"])
                            .filter((s) => !!s)
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["ARIN"] &&
                    !!report?.modules["ARIN"].length && (
                      <TableRow>
                        <TableCell>
                          {t("ARIN")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.ARIN[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {/* sort by date, take first one and find role with abuse in it (lower case first) show role and email / tel */}
                          {/* TODO - instead of getARINContactString, get a React fragment / Box and include a clipboard button that'll copy
                                 the email address, alternatively, just add after wrapping in Fragment */}
                          {report.modules["ARIN"]
                            .map(
                              (a) =>
                                getARINContactString(a) +
                                " (" +
                                a["lastChangedDate"].split(" ")[0] +
                                ")"
                            )
                            .join("; ")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["MAXMIND"] &&
                    !!report?.modules["MAXMIND"].length && (
                      <TableRow>
                        <TableCell>
                          {t("MAXMIND")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.MAXMIND[1]}
                          />
                        </TableCell>
                        <TableCell>
                          <MaxmindTableCell
                            maxmindReport={report.modules["MAXMIND"]}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["VIRUSTOTAL"] &&
                    report.modules["VIRUSTOTAL"]["avResults"] &&
                    Object.keys(
                      report.modules["VIRUSTOTAL"]["avResults"]
                    ).filter(
                      (k) => !!report.modules["VIRUSTOTAL"]["avResults"][k]
                    ).length && (
                      <TableRow>
                        <TableCell>
                          {t("VIRUSTOTAL")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.VIRUSTOTAL[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {Object.keys(
                            report.modules["VIRUSTOTAL"]["avResults"]
                          )
                            .filter(
                              (k) =>
                                !!report.modules["VIRUSTOTAL"]["avResults"][k]
                            )
                            .map(
                              (k) =>
                                report.modules["VIRUSTOTAL"]["avResults"][k] +
                                " " +
                                k
                            )
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  {!!report?.modules["NCTNS"] &&
                    !!report?.modules["NCTNS"].length && (
                      <TableRow>
                        <TableCell>
                          {t("NCTNS")}
                          <HelpToolTip
                            help={"" + BorealisModulesHelp.NCTNS[1]}
                          />
                        </TableCell>
                        <TableCell>
                          {report?.modules["NCTNS"]
                            .filter((n: any) => n.customer?.active || n.asnCustomer?.active)
                            .map(
                              (n: any) =>
                                n["ip"] +
                                " / A" +
                                n["asn"] +
                                (n.customer?.name ? " / " : "") +
                                (n.customer?.name ? n.customer.name : "") +
                                (n.asnCustomer?.name ? " / " : "") +
                                (n.asnCustomer?.name ? n.asnCustomer.name : "")
                              )
                            .join("; ")}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}

      {!!report.domain && (
        <BorealisDNSReolution
          dnsResolutionList={dnsResolutionList}
          query={query}
        />
      )}

      {!!Object.keys(report.modules).filter((f) => "BEAVER" !== f).length && (
        <>
          <br /> <Typography variant="h6">{t("Modules")}:</Typography>
        </>
      )}
      {/* as mentioned above, if the module is emtpy, we really shouldn't be showing it */}
      {Object.keys(report.modules)
        .filter(
          (f) => "BEAVER" !== f && getModuleCount(report.modules[f]) !== 0
        )
        .sort()
        .map((moduleName, i) => (
          <BorealisModuleCard
            key={"module_" + i}
            moduleName={moduleName}
            moduleData={report.modules[moduleName]}
          />
        ))}
    </Box>
  );
}

/**
 * Prettifies the ARIN contact (kind of)
 */
const getARINContactString = (a) => {
  // only take the first one with ABUSE in it, then prettify it
  return [
    [
      ...a["contact"].filter((c) => _.toLower(c["role"]).includes("abuse")),
      { role: "N/A", emails: ["N/A"], contactName: "N/A" },
    ][0],
  ].map(
    (a) =>
      "Role: " +
      a.role +
      "; Name: " +
      a.contactName +
      "; Email: " +
      a.emails[0] +
      (a.tels && a.tels.length ? "; Tel: " + a.tels[0] : "")
  );
};

export function MaxmindTableCell({ maxmindReport }: { maxmindReport: any }) {
  return (
    <>
      {maxmindReport
        .filter((a) => !!a["ipLocation"])
        .map((a, i) => (
          <React.Fragment key={i}>
            {a["ipLocation"]["ip"]}
            <Flag
              style={{ maxHeight: "1em", marginLeft: "0.5em" }}
              countryCode={a["ipLocation"]["countryCode"]}
            />
            {" ("}
            {a["ipLocation"]["databaseDate"]}
            {"); "}
          </React.Fragment>
        ))}
    </>
  );
}

export function BorealisNumberChip({ label }: { label: string | number }) {
  return (
    <Chip
      size="small"
      color="secondary"
      label={label}
      style={{ verticalAlign: "top" }}
    />
  );
}

// sometimes we want to show a flag instead
function BorealisNumberChipOrFlag({
  moduleData,
  moduleName,
}: {
  moduleData: any;
  moduleName: string;
}) {
  if (
    "NEUSTAR" === moduleName &&
    !!moduleData["connectivity"] &&
    !!moduleData["connectivity"]["country_code"]
  ) {
    return (
      <Flag
        countryCode={_.upperCase(moduleData["connectivity"]["country_code"])}
        style={{ maxHeight: "1em", marginLeft: "5px" }}
      />
    );
  } else if (
    "MAXMIND" === moduleName &&
    moduleData.length &&
    moduleData[0]["ipLocation"] &&
    moduleData[0]["ipLocation"]["countryCode"]
  ) {
    return (
      <Flag
        countryCode={_.upperCase(moduleData[0]["ipLocation"]["countryCode"])}
        style={{ maxHeight: "1em", marginLeft: "5px" }}
      />
    );
  } else {
    return (
      <BorealisNumberChip
        label={getModuleCountOrStringForChip(moduleData, moduleName)}
      />
    );
  }
}

export function BorealisModuleCard({
  moduleName,
  moduleData,
}: {
  moduleName: string;
  moduleData: any;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  // default is minimized
  const [minimized, setMinimized] = React.useState(true);

  return (
    <Box mt={2}>
      <Card>
        <CardHeader
          onClick={() => setMinimized(!minimized)}
          style={{ cursor: "pointer" }}
          title={
            <>
              {moduleName + " "}
              {!!getModuleCountOrStringForChip(moduleData, moduleName) && (
                <BorealisNumberChipOrFlag
                  moduleData={moduleData}
                  moduleName={moduleName}
                />
              )}
            </>
          }
          className={classes.ch}
          action={
            <div style={{marginTop: "8px", marginRight: "8px"}}>
              <HelpToolTip help={BorealisModulesHelp[moduleName][1]} />
              <Tooltip
                title={minimized ? t("Show Module") : t("Hide Module")}
                arrow
                placement="left"
              >
                <IconButton size="small" onClick={() => setMinimized(!minimized)}>
                  {minimized ? (
                    <ExpandMoreOutlinedIcon />
                  ) : (
                    <ExpandLessOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          }
        />
        {!minimized && (
          <CardContent>
            <ModuleDataView moduleData={moduleData} />
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
